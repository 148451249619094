export default {
  created () {
    var info = window.localStorage.getItem(this.pageName)
    if (info) {
      this.tableColumns = JSON.parse(info)
    }
  },
  mounted () {
    this.getSubject()
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
  },
  methods: {
    initData (page, limit) {
      this.form.page = page
      this.form.limit = limit
      this.$http({
        url: '/api/v1/papers/index',
        method: 'get',
        params: this.form
      }).then(res => {
        this.info = res.data
        this.tableData = res.data.list
        this.totalElements = res.data.count
        this.tableLoading = false
      })
    },
    getSubject () {
      this.$http({
        url: '/api/v1/papers/subject',
        method: 'get'
      }).then(res => {
        this.suubjectList = res.data.subject
      })
    },
    // 修改状态为未改
    editStatus (row) {
      this.$confirm("确定将其修改为未批改?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/papers/edit_paper_status',
          method: 'post',
          data: {
            user_paper_id: row.user_paper_id
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '操作成功!',
            type: 'success'
          });
          this.refresh()
        })
      })
    },
    toPage (row) {
      this.$router.push('/checkTestAll/checkTestPage?id=' + row.user_paper_id + '&judge_status=' + row.judge_status)
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    // 触发子组件
    setList (list) {
      this.showList = list
    },
    changeChecked (str) {
      if (this.form[str] == '') {
        this.form[str] = 'on'
      } else {
        this.form[str] = ''
      }
      this.search()
    },
    checkInfo (row) {
      this.$router.push('/checkTestAll/checkTestPage?id=' + row.user_paper_id + '&judge_status=' + row.judge_status + '&isCheck=' + 1)
    },
    showController () {
      this.$refs.ShuttleRef.columns = JSON.parse(JSON.stringify(this.tableColumns))
      this.$refs.ShuttleRef.dialogVisible = true
    },
    getColumns (arr) {
      this.tableColumns = arr
      window.localStorage.setItem(this.pageName, JSON.stringify(arr))
      this.$forceUpdate()
    }
  }
}